import React from "react";
import "../assets/css/editor.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

function Editor() {
  return (
    <Container>
      <div className="page-header">
        <h1>Editor Section</h1>
      </div>
      <div className="main-section">
        <Row>
          <Col sm={2} className="menu-sidebar">
            <div className="menu-title">Editor Menu</div>
            <div className="menu-item">Manage Articles</div>
            <div className="menu-item">Sample Menu</div>
            <div className="menu-item">Other Items</div>
          </Col>
          <Col sm={10} className="main-editor-content">
            <Container>
              <div className="top-action-bar">
                <Button variant="success" size="sm">
                  Add Article
                </Button>
              </div>

              <Table className="data-table" striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Date</th>
                    <th className="table-action">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Blog One</td>
                    <td>July 25, 2023</td>
                    <td className="table-action">
                      <Button variant="warning" size="sm">
                        Edit
                      </Button>{" "}
                      <Button variant="danger" size="sm">
                        Delete
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Blog Two</td>
                    <td>July 25, 2023</td>
                    <td className="table-action">
                      <Button variant="warning" size="sm">
                        Edit
                      </Button>{" "}
                      <Button variant="danger" size="sm">
                        Delete
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Blog Three</td>
                    <td>July 25, 2023</td>
                    <td className="table-action">
                      <Button variant="warning" size="sm">
                        Edit
                      </Button>{" "}
                      <Button variant="danger" size="sm">
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Editor;
