import React from "react";
import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-company-name">Mad Copper</div>
      <div className="footer-copyright">Copyright ©2023</div>
    </div>
  );
}

export default Footer;
