import React, { useState, useEffect, useContext } from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        border: 0,
        borderRadius: 5,
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));

  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <img alt="Left" title="Left" src="/assets/images/arrow_left.svg" height="20" />
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible);

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <img alt="Right" title="Right" src="/assets/images/arrow_right.svg" height="20" />
    </Arrow>
  );
}
