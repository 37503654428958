import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../assets/css/reglogin.css";
import axios from "../api/axios";
import axiosEx from "axios";
const LOGIN_URL = "/auth/login";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const __googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => __handleGoogleSuccess(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const __handleGoogleSuccess = async (response) => {
    try {
      const result = await axiosEx.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
          Accept: "application/json",
        },
      });
      console.log(result?.data);
      if (result?.data) {
        const response = await axios.post("/auth/google-login", result?.data, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        setAuth(response?.data);
        console.log(response?.data);
        navigate(from, { replace: true });
      }
    } catch (err) {
      console.log("ERROR:", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify({ email, password }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setAuth(response?.data);
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 422) {
        setErrMsg("Missing credentials");
      } else if (err.response?.status === 404) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    // setPersist((prev) => !prev);
    setPersist(true);
  };

  useEffect(() => {
    // localStorage.setItem("persist", persist);
    localStorage.setItem("persist", true);
  }, [persist]);

  return (
    <div className="login-section">
      <div className="form-container">
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
        </p>
        <div className="login-title">Login</div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control ref={emailRef} type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} required />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group> */}
          <div className="form-buttons">
            <Button variant="primary" type="submit">
              Submit
            </Button>
            <span className="line">
              <Link to="/register">Sign Up</Link>
            </span>
          </div>
        </Form>
        <hr />
        <div className="other-container">
          <button className="button-google" onClick={() => __googleLogin()}>
            Sign in with Google 🚀
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
