/* eslint-disable jsx-a11y/anchor-is-valid */
// https://ui-avatars.com/api/?background=random&size=64&rounded=true&bold=true&name=harris+loable
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { googleLogout } from "@react-oauth/google";
import TopHorizontalList from "./TopHorizontalList";

function TopNavigation({ allowedRoles }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const [fullName, setFullName] = useState();
  const [profilePic, setProfilePic] = useState();

  const __signOut = async () => {
    if (auth?.type === "google") {
      googleLogout();
    }
    await logout();
    navigate("/");
  };

  useEffect(() => {
    if (auth?.userId) {
      if (auth.firstName && auth.lastName) {
        setFullName(`${auth.firstName} ${auth.lastName}`);
      } else {
        setFullName(auth.email);
      }
    }
  }, [auth?.firstName, auth?.lastName, auth?.email, auth?.userId]);

  useEffect(() => {
    if (fullName) {
      if (auth?.picture) {
        setProfilePic(auth.picture);
      } else {
        setProfilePic(`https://ui-avatars.com/api/?background=random&size=64&rounded=true&bold=true&name=${fullName}`);
      }
    }
  }, [fullName, auth?.picture]);

  return (
    <>
      <Navbar expand="lg" className="fixed-top" style={{ display: "flex", flexDirection: "column", backgroundColor: "white" }}>
        <Container>
          <Navbar.Brand href="/" style={{ fontWeight: "bold", color: "#994D0D", marginRight: 50 }}>
            <img alt="" src="/assets/images/mad-copper-logo.png" height="30" className="d-inline-block align-top" />
            Mad Copper
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={location.pathname} className="me-auto justify-content-start">
              <Nav.Link href="/" className="my_nav-txt-link">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="my_nav-txt-link">
                About Us
              </Nav.Link>
            </Nav>

            <Nav className="justify-content-start flex-nowrap" style={{ display: "flex", flexDirection: "row" }}>
              <Nav.Link href="/" className="p-2">
                <img alt="Twitter" title="Twitter" src="/assets/images/twitter-logo.svg" width="20" />
              </Nav.Link>
              <Nav.Link href="/" className="p-2">
                <img alt="Linkedin" title="Linkedin" src="/assets/images/linkedin-logo.svg" width="20" />
              </Nav.Link>
              <Nav.Link href="/" className="p-2">
                <img alt="Facebook" title="Facebook" src="/assets/images/facebook-logo.svg" width="20" />
              </Nav.Link>
            </Nav>

            <Nav className="ms-auto justify-content-end">
              {!auth?.userId ? (
                <>
                  <Button variant="outline-success btn-sm mx-1" onClick={() => navigate("/register")}>
                    Register for a Free Plan
                  </Button>
                  <Button variant="outline-success btn-sm mx-1" onClick={() => navigate("/login")}>
                    Login
                  </Button>
                </>
              ) : (
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", padding: "0.3rem" }}>
                    <img alt={fullName} title={fullName} src={profilePic} width="30" height="30" style={{ borderRadius: 25 }} />
                  </div>
                  <NavDropdown title={`${auth?.email}`} id="basic-nav-dropdown" style={{ display: "flex" }}>
                    <NavDropdown.Item href="#action">Profile</NavDropdown.Item>
                    {(auth?.roles.includes(allowedRoles.Editor) || auth?.roles.includes(allowedRoles.Admin)) && (
                      <>
                        <NavDropdown.Divider />
                        {auth?.roles.includes(allowedRoles.Editor) && <NavDropdown.Item href="/editor">Editor Page</NavDropdown.Item>}
                        {auth?.roles.includes(allowedRoles.Admin) && <NavDropdown.Item href="/admin">Admin Page</NavDropdown.Item>}
                      </>
                    )}
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={__signOut}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Container style={{ marginTop: 10 }}>
          <TopHorizontalList />
        </Container>
      </Navbar>
    </>
  );
}

export default TopNavigation;
