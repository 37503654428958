import { Link } from "react-router-dom";
import Users from "../components/Users";
import "../assets/css/admin.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const Admin = () => {
  return (
    <Container>
      <div className="page-header">
        <h1>Admin Section</h1>
      </div>
      <div className="main-section">
        <Row>
          <Col sm={2} className="menu-sidebar">
            <div className="menu-title">Admin Menu</div>
            <div className="menu-item">Manage Users</div>
            <div className="menu-item">Other Items</div>
          </Col>
          <Col sm={10} className="main-admin-content">
            <Users />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Admin;
