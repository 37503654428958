/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet } from "react-router-dom";
import TopNavigation from "./TopNavigation";
import TopHorizontalList from "./TopHorizontalList";
import Footer from "./Footer";

const Layout = ({ allowedRoles }) => {
  return (
    <>
      <TopNavigation allowedRoles={allowedRoles} />
      <main className="App">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
