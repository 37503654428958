/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import _f from "../components/functions";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const Users = () => {
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [trigUsers, setTrigUsers] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.get("/user", {
          cancelToken: cancelToken.token,
        });
        setUsers(response.data);
        setIsLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
        } else {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getUsers();

    return () => {
      cancelToken.cancel();
    };
  }, [trigUsers]);

  return (
    <>
      <Container>
        <div className="top-action-bar">
          <Button variant="success" size="sm">
            Add User
          </Button>
        </div>

        <Table className="data-table" striped bordered hover variant="light">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Date</th>
              <th className="table-action">Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              <>
                {users?.length ? (
                  <>
                    {users.map((user, i) => (
                      <tr key={i}>
                        <td>{user?.id}</td>
                        <td>{user?.email}</td>
                        <td>{_f.toShortDate(user?.createdAt)}</td>
                        <td className="table-action">
                          <Button variant="warning" size="sm">
                            Edit
                          </Button>{" "}
                          <Button variant="danger" size="sm">
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={4}></td>No users found...
                  </tr>
                )}
              </>
            ) : (
              <tr>
                <td colSpan={4}></td>Fetching data...
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Users;
