import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const refresh = async () => {
    const response = await axios.get(`/auth/refresh`, {
      withCredentials: true,
    });
    if (response.data.error) {
      console.error(response.data.error);
      setAuth({});
    } else {
      if (response.data.userId) {
        setAuth((prev) => {
          return {
            ...prev,
            ...response.data,
          };
        });
      }
    }
    return response.data.accessToken ?? null;
  };
  return refresh;
};

export default useRefreshToken;
