import axios from "axios";
const BASE_URL = window.location.href.includes("localhost") ? `http://localhost:3102/v1` : `https://api.ppm.one/madcopper/v1`;

export default axios.create({
  baseURL: `${BASE_URL}/gt`,
});

export const axiosPrivate = axios.create({
  baseURL: `${BASE_URL}/pt`,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
