import React, { useContext } from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function Card({ data, itemId }) {
  const visibility = useContext(VisibilityContext);
  const visible = visibility.isItemVisible(itemId);
  // console.log(data);

  return (
    <div
      // onClick={() => onClick()}
      role="button"
      style={{
        // border: "1px solid",
        display: "inline-block",
        margin: "0 10px",
        width: "160px",
        userSelect: "none",
      }}
      tabIndex={0}
      //   className="card"
    >
      <div>
        <div>
          <span className="stock-list">{`${data.code} ${data.sharePrice}`}</span>
          {data.status === "up" ? (
            <img alt="Up" title="Up" src="/assets/images/arrow_up.svg" height="20" style={{ pointerEvents: "none", marginTop: "-3px" }} />
          ) : data.status === "down" ? (
            <img alt="Down" title="Down" src="/assets/images/arrow_down.svg" height="20" style={{ pointerEvents: "none", marginTop: "-3px" }} />
          ) : (
            <img
              alt="Same"
              title="Same"
              src="/assets/images/line_dash.svg"
              height="20"
              width="10"
              style={{ pointerEvents: "none", marginTop: "-3px", marginLeft: 5 }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
