const _f = {};

_f.toShortDate = (date) => {
  const dateVal = new Date(date);
  if (isNaN(dateVal)) return;
  return dateVal
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");
};

export default _f;
