import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      async (response) => {
        const originalConfig = response.config;
        if (response.status === 203 && response.data.error === "accessTokenExpiredError" && !originalConfig._retry) {
          originalConfig._retry = true;
          const newAccessToken = await refresh();
          if (!newAccessToken) {
            return Promise.reject("Authorization error: Cannot refresh token");
          } else {
            console.count("ATR!");
            originalConfig.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosPrivate(originalConfig);
          }
        }
        return response;
      },
      async (error) => {
        // const originalConfig = error.config;
        // if (error.response) {
        // console.log(error.response);
        // if (error.response.status === 203 && !originalConfig._retry) {
        //   originalConfig._retry = true;
        //   const newAccessToken = await refresh();
        //   if (!newAccessToken) {
        //     return Promise.reject("Authorization error: Cannot refresh token");
        //   } else {
        //     originalConfig.headers["Authorization"] = `Bearer ${newAccessToken}`;
        //     return axiosPrivate(originalConfig);
        //   }
        // }

        // if (error.response.status !== 203) {
        //   // Do something
        //   console.log(`${error.response.status} ERROR`);
        // return Promise.reject(error.response.data);
        // }
        // }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
