import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./TopHorizontalArrows";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useMenuDrag from "../hooks/useMenuDrag";
import { Card } from "./TopHorizontalCard";
import axios from "../api/axios";

const elemPrefix = "test";
const getId = (index) => `${elemPrefix}${index}`;

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function TopHorizontalList() {
  const [items] = useState(getItems);
  const [isLoading, setIsLoading] = useState(true);
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/scrape/asxCopperMining");
        // console.log("ORIG:", response.data);
        setStocks(response.data);
        if (response.data && response.data.length > 0) {
          let stockData = [];
          for (const item of response.data) {
            let stockItem = {};
            stockItem.code = item.code.replace("ASX:", "") + ":";
            stockItem.sharePrice = item.sharePrice;
            if ((stockItem.status = item.percentChange.includes("+"))) {
              stockItem.status = "up";
            } else if ((stockItem.status = item.percentChange.includes("-"))) {
              stockItem.status = "down";
            } else {
              stockItem.status = "same";
            }
            // console.log(stockItem);
            stockData = [...stockData, stockItem];
          }
          setStocks(stockData);
        }

        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    getUsers();
  }, []);

  // NOTE: for drag by mouse
  const { dragStart, dragStop, dragMove, dragging } = useMenuDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  // const [selected, setSelected] = useState("");
  // const handleItemClick = (itemId) => () => {
  //   if (dragging) {
  //     return false;
  //   }
  //   setSelected(selected !== itemId ? itemId : "");
  // };
  return (
    <>
      {!isLoading ? (
        <Container>
          <div onMouseLeave={dragStop}>
            <ScrollMenu
              className="top-hscroll-menu"
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onWheel={onWheel}
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
            >
              {stocks.map((data, i) => (
                <Card
                  data={data}
                  itemId={i} // NOTE: itemId is required for track items
                  key={i}
                  // onClick={handleItemClick(id)}
                  // selected={id === selected}
                />
              ))}
            </ScrollMenu>
          </div>
        </Container>
      ) : (
        <Container>
          <div>Loading...</div>
        </Container>
      )}
    </>
  );
}

export default TopHorizontalList;
