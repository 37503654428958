import { useNavigate, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { Container, Image } from "react-bootstrap";

const Home = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  return (
    <Container>
      <div className="hero-container">
        <div className="hero-featured">
          <div className="featured-article">
            <div className="featured-article-image">
              <Image alt="" src="/assets/article/images/8a.png" fluid />
            </div>
            <div className="featured-article-date">July 25, 2023</div>
            <div className="featured-article-title">My featured article</div>
            <div className="featured-article-content">
              After several months of learning in the Frontend Developer Career Path, I've made the big jump over to the Bootcamp to get expert code
              reviews of my Solo Projects projects and meet like-minded peers.
            </div>
          </div>
        </div>
        <div className="hero-banner">
          <Image alt="" src="/assets/article/images/featured-banner.png" fluid />
        </div>
      </div>
      <div className="blog-container">
        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/1.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog one</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>

        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/2.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog two</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>

        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/3.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog three</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>

        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/4.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog four</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>

        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/5.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog five</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>

        <div className="blog-article">
          <div className="blog-article-image">
            <Image alt="" src="/assets/article/images/6.png" fluid />
          </div>
          <div className="blog-article-date">July 25, 2023</div>
          <div className="blog-article-title">Blog six</div>
          <div className="blog-article-content">
            I'm excited to start a new learning journey as a Scrimba Bootcamp student! After several months of learning in the Frontend Developer
            Career Path.
          </div>
        </div>
      </div>

      <div className="view-more">
        <div className="view-more-text">View More</div>
      </div>
    </Container>
  );
};

export default Home;
